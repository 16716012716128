import * as React from 'react';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { DropdownOptions } from './types';
import { LoadingPlaceholder } from 'components/CommonComponents/LoadingPlaceholder';

type Props = {
  options: DropdownOptions[];
  onChange(value: unknown): void;
  loading?: boolean;
}

const Dropdown: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickDropdown = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (value: unknown): void => {
    props.onChange(value);
    handleClose();
  };

  const handleClose = (): void => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const btnText = props.children as string || 'Open dropdown';

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickDropdown}
        disabled={props.loading}
      >
        {props.loading ? <LoadingPlaceholder text=' ' size={20} /> : btnText}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {props.options.map((option, idx) =>
          <MenuItem
            key={idx}
            onClick={() => handleClick(option.value)}
          >
            {option.label}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default React.memo(Dropdown);

