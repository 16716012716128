import React from 'react';
import cn from 'classnames';

import { FormControlLabel, Switch as MUISwitch, SwitchProps } from '@mui/material';

type Props = {
  children: React.ReactNode;
  testId: string;
  onChange?(value: boolean): void;
  color?: SwitchProps['color'];
  className?: string;
  isDisabled?: boolean;
  checked?: boolean;
}

const Switch: React.FC<Props> = (props) => {
  const [checked, setChecked] = React.useState(props.checked ?? false);

  const handleChange = (): void => {
    if (!props.isDisabled) {
      setChecked(!checked);

      if (props.onChange) {
        props.onChange?.(!checked);
      }
    }
  };

  return (
    <FormControlLabel
      label={props.children}
      className={cn([props.className])}
      control={
        <MUISwitch
          onChange={handleChange}
          checked={checked}
          color={props.color || 'secondary'} // TODO move colors to enum
        />}
    />
  );
};

export default React.memo(Switch);