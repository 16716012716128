import React, { PropsWithChildren } from 'react';

import { getCountries } from 'api/person-api';
import { useAuthQueryNew } from 'hooks';
import { QueryResource } from 'models/resources';
import { useCountryStore } from 'stores/countries';
import { useUserStore } from 'stores/user';
import { selectIsAuthorized } from 'stores/user/selectors';
import { Loader } from 'components/ui';

const CoreDataLoader: React.FC<PropsWithChildren> = (props) => {
  const isUserAuthorized = useUserStore(selectIsAuthorized);
  const setCountries = useCountryStore(state => state.setCountries);

  const { isLoading } = useAuthQueryNew(QueryResource.Countries, getCountries, {
    staleTime: Infinity,
    enabled: isUserAuthorized,
    onSuccess: (data) => setCountries(data)},
  );

  if (isLoading) {
    return <Loader center />;
  }

  return props.children as React.ReactElement;
};

export default CoreDataLoader;