import React from 'react';

import { Tooltip, TooltipProps } from '@mui/material';

import { Position } from './types';

type Props = {
  showHint?: boolean;
  title: string;
  testId: string;
  position?: Position;
}

type HintProps = Props & TooltipProps;

const Hint: React.FC<React.PropsWithChildren<HintProps>> = (props) => {
  const { showHint = true, title, position = Position.Bottom, testId, ...properties } = props;

  if (showHint) {
    return (
      <Tooltip placement={position} title={title} data-test-id={testId} {...properties}>
        {props.children as React.ReactElement}
      </Tooltip>
    );
  }
  return <div data-test-id={testId}>{props.children}</div>;
};

export default React.memo(Hint);