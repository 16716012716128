import React from 'react';
import cn from 'classnames';

import { Grid as MUIGrid } from '@mui/material';

import { GridProps } from '../types';

import styles from './Row.module.scss';

type Props = {
  noBottomMargin?: boolean;
  noTopMargin?: boolean;
  className?: string;
  testId?: string;
}

type RowProps = Props & GridProps;

const Grid: React.FC<React.PropsWithChildren<RowProps>> = (props) => {
  const { testId = 'gridRow', className, noBottomMargin, noTopMargin, ...rowProps } = props;

  const classes = cn([className, styles.row, {
    [styles.noBottomMargin]: noBottomMargin,
    [styles.noTopMargin]: noTopMargin,
  }]);

  return (
    <MUIGrid
      container
      data-test-id={testId}
      className={classes}
      {...rowProps}
    >
      {props.children}
    </MUIGrid>
  );
};

export default React.memo(Grid);