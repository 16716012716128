import { IAuthOrganization, IAuthTeam } from 'api/auth-api';
import { IUserState } from './';
import { ROLES_LIST } from 'components/Auth/authConfig';

export const selectIsAuthorized = (state: IUserState): boolean => Boolean(state.user.id);

export const selectOrganizations = (state: IUserState): IAuthOrganization[] =>
  state.user.organizations;

export const selectPersonCode = (state: IUserState): string | null =>
  state.user.personCode;

export const selectTeamCode = (state: IUserState): string | null =>
  state.user.teamCode;

export const selectUserTeams = (state: IUserState): IAuthTeam[] =>
  state.user.organizations.flatMap(org => org.teams);

export const selectUserRoles = (state: IUserState): ROLES_LIST[] =>
  state.user.roles;

export const selectRegion = (state: IUserState): string | null =>
  state.user.region;

export const selectUserName = (state: IUserState): string => state.user.name;
// email as uniqueName
export const selectUserEmail = (state: IUserState): string => state.user.uniqueName;

export const selectUserIsAdmin = (state: IUserState): boolean => {
  return [ROLES_LIST.ROLE_KIMEP_ADMIN, ROLES_LIST.ROLE_SUPERUSER].some(role =>
    state.user.roles.includes(role));
};
