export enum QueryResource {
  Countries = 'countries',
  Emergencies = 'emergencies',
  EmergenciesPublic = 'emergenciesPublic',
  EmergenciesOpenCalls = 'emergenciesOpenCalls',
  EmergencyEoi = 'emergencyEoi',
  EmergencyEmtcc = 'emergencyEmtcc',
  EmtccMembers = 'emtccMembers',
  EOIForms = 'eoiForms',
  EmergencyTeams = 'emergencyTeams',
  EmergencyReports = 'emergencyReports',
  EmergencyEmtccReports = 'emergencyEmtccReports',
  EmergencyParticipants = 'emergencyParticipants',
  EmergencyAccess = 'emergencyAccess',
  EmergencyAdditionalFiles = 'emergencyAdditionalFiles',
  Person = 'person',
  Mentor = 'mentor',
  TeamsOrganizations = 'teamsOrganizations',
  Team = 'team',
  FieldTeam = 'fieldTeam',
  FieldTeamEquipments = 'fieldTeamEquipments',
  FieldTeamStaffings = 'fieldTeamStaffings',
  DeploymentEquipment = 'deploymentEquipment',
  DeploymentStaffing = 'deploymentStaffing',
  DeploymentReports = 'deploymentReports',
  ProcessMentors = 'processMentors',
  TeamOrganization = 'teamOrganization',
  OrganizationTeams = 'organizationTeams',
  Organization = 'organization',
  ReclassificationProcess = 'reclassificationProcess',
  VisitInfo = 'visitInfo',
  MobileLabList = 'mobileLabList',
  MyEmergencies = 'myEmergencies',
}