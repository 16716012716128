import React from 'react';
import cn from 'classnames';

import { Alert } from '@mui/material';

type Props = {
  type: 'info' | 'warning' | 'error' | 'success';
  testId: string;
  className?: string;
  icon?: React.ElementType;
}

import styles from './Notification.module.scss';

const iconColor = {
  info: 'secondary',
  warning: 'warning',
  error: 'error',
  success: 'success',
};

const sxOptions = { '& .MuiAlert-message': { width: '100%' } };

const Notification: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const icon = React.useMemo(() => {
    if (props.icon) {
      const Icon = props.icon;
      return <Icon fontSize='small' color={iconColor[props.type]} />;
    }
    return false;
  }, [props.icon]);

  return (
    <Alert
      className={cn([props.className, styles[props.type], styles.notification])}
      data-test-id={props.testId}
      icon={icon}
      severity={props.type}
      sx={sxOptions}
    >
      {props.children}
    </Alert>
  );
};

export default React.memo(Notification);
