import { create } from 'zustand';

import { ICountryState } from './types';
import { normalizeCountries } from './helpers';

export const useCountryStore = create<ICountryState>((set) => ({
  countries: [],
  countryMap: {},
  setCountries: (countries) => set(() => ({ countries, countryMap: normalizeCountries(countries)})),
}));
