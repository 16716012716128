import React, { PropsWithChildren } from 'react';
import { enGB } from 'date-fns/locale';

import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns as adapter } from '@mui/x-date-pickers/AdapterDateFns';
import CssBaseline from '@mui/material/CssBaseline';

import { whoTheme } from 'theme/theme';

const AppTheme: React.FC<PropsWithChildren> = (props) => {
  return (
    <ThemeProvider theme={whoTheme}>
      <LocalizationProvider dateAdapter={adapter} adapterLocale={enGB}>
        <CssBaseline />
        {props.children}
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default AppTheme;