import React from 'react';
import cn from 'classnames';

import { Box, FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';

import styles from './Checkbox.module.scss';

type Props = {
  isDisabled?: boolean;
  isRequired?: boolean;
  children: React.ReactNode;
  testId: string;
  onChange?(value: boolean): void;
  className?: string;
  checked?: boolean;
}

const Checkbox: React.FC<Props> = (props) => {
  const [checked, setChecked] = React.useState(props.checked ?? false);

  const handleChange = (): void => {
    if (!props.isDisabled) {
      setChecked(!checked);

      if (props.onChange) {
        props.onChange(!checked);
      }
    }
  };

  return (
    <Box>
      <FormControlLabel
        className={cn([props.className, styles.checkbox])}
        label={props.children}
        control={
          <MuiCheckbox
            checked={checked} 
            data-test-id={props.testId}
            onChange={handleChange}
            disabled={props.isDisabled}
          />
        }
      />
    </Box>
  );
};

export default React.memo(Checkbox);
