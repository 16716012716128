import { INfpProfile } from '../components/CommonComponents/NFPprofile';
import {fetchFile, fetchJson, uploadImage, uploadSingleFile} from './helpers/request';
import { ICountryNFP } from './interfaces/ICountryNFPs';
import { IExpertAssignment } from './governance-api';
import { CreateURLSearchParams } from './helpers/createURLSearchParams';
import {IFileContent} from "./filestorage-api";

const BASE_URL = process.env.REACT_APP_API_PERSON_BASE_URL;

export interface IPerson {
  personCode: string | null;
  title: string;
  firstName: string;
  lastName: string;
  secondName: string | null;
  fullName: string;
  hasNfpProfile?: boolean;
  country: {
    name: string,
    region: string,
    isoAlpha3Code: string,
  } | null;
  cityOfResidence: string;
  organization: string;
  orgWebsite: string;
  position: string;
  comment: string;
  active: boolean;
  emtFp: boolean;
  mentor: boolean;
  regionalFp: boolean;
  nationalFp: boolean;
  isTeamLead: boolean;
  nfpCountry?: {
    name: string,
    region: string,
    isoAlpha3Code: string,
  } | null,
  tfpCountry?: {
    name: string,
    region: string,
    isoAlpha3Code: string,
  } | null,
  emtCc: boolean;
  languages: string[];
  otherLanguages: string;
  contacts: IContact[];
  uniqueUserName: string;
  version: string;
  avatar: string;
  validation?: IValidationData;
  createdDate?: string;
  createdBy?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
}

export interface IValidationData {
  newValidationStatus?: ValidationStatusEnum | null,
  validationStatus?: ValidationStatusEnum | null,
  lastValidatedByOwner?: string,
  ownerComment?: string
}

export enum ValidationStatusEnum {
  RequiresValidation = 'REQUIRES_VALIDATION',
  Valid = 'VALID',
  NotValid = 'NOT_VALID',
}


export interface IContact {
  id: number | null;
  contactType: string;
  contactValue: string;
  default: boolean;
}

export interface IPersonShort {
  personCode: string,
  fullName: string,
  organization: string,
  position: string,
  phone: string,
  email: string;
}

export interface Country {
  name: string;
  region: string,
  isoAlpha3Code: string;
  eu: boolean;
  ucpm: boolean;
  workspaceAccess: boolean;
  hasWorkspace: boolean;
}

export const dummyPerson: IPerson = {
  personCode: null,
  title: '',
  firstName: '',
  lastName: '',
  secondName: '',
  fullName: '',
  country: null,
  cityOfResidence: '',
  organization: '',
  orgWebsite: '',
  position: '',
  comment: '',
  active: true,
  emtFp: false,
  mentor: false,
  regionalFp: false,
  nationalFp: false,
  isTeamLead: false,
  emtCc: false,
  contacts: [],
  languages: [],
  otherLanguages: '',
  uniqueUserName: '',
  version: '',
  avatar: '',
  validation: undefined
};

export interface ICountryTeams {
  id: number,
  country: Country,
  nfpCount: number,
  primaryNfp: {
    personCode: string,
    fullName: string,
    email: string,
    organization: string,
    phone: string,
    position: string,
  },
  teamsCount: number,
  primaryNfpDateStart: string;
}

export interface FeedbackItem {
  id: number,
  username: string,
  date: string,
  text: string;
}

export interface ISimilarPersons {
  type: string,
  model: IPerson[];
}

export function getPersonList(init?: RequestInit) {
  return fetchJson<IPerson[]>(`${BASE_URL}/person`, init);
}

export function fetchSimilarPersons(fullName: string, email: string, init?: RequestInit): Promise<ISimilarPersons> {

  if (!fullName && !email) {
    return Promise.resolve({ type: "", model: [] } as ISimilarPersons);
  }

  const encodedFullName = fullName? encodeURIComponent(fullName.slice(0, 50)): "";
  const encodedEmail = email? encodeURIComponent(email.slice(0, 50)) : "";

  return fetchJson<ISimilarPersons>(`${BASE_URL}/name?fullName=${encodedFullName}&email=${encodedEmail}`, init);
}

export function getPersonData(personCode: string, init?: RequestInit) {
  return fetchJson<IPerson>(`${BASE_URL}/person/${personCode}`, init);
}

export function updatePersonData(newData: IPerson, init?: RequestInit) {
  return fetchJson<IPerson>(`${BASE_URL}/person/${newData.personCode}`, {
    method: 'PUT',
    body: JSON.stringify(newData),
    headers: init?.headers
  });
}

export function createPerson(personData: IPerson, init?: RequestInit) {
  return fetchJson<IPerson>(`${BASE_URL}/person`, {
    method: 'POST',
    body: JSON.stringify(personData),
    headers: init?.headers
  });
}

export function filterByGuidsPerson(filter: string, init?: RequestInit) {
  return fetchJson<IPerson[]>(`${BASE_URL}/search`, {
    method: 'POST',
    body: JSON.stringify({ guids: filter }),
    headers: init?.headers
  });
}

export function getCountries(init?: RequestInit) {
  return fetchJson<Country[]>(`${BASE_URL}/references/countries`, init);
}

export function getPhotoLink(personId: string, init?: RequestInit) {
  return fetch(`${BASE_URL}/person/${personId}/photo`, init).then(response => response.blob());
}

export async function getPersonPhotoSrc(personId: string, init?: RequestInit) {
  const blob = await getPhotoLink(personId, init);
  return URL.createObjectURL(blob);
}

export function uploadPhoto(personId: string, photo: File, init?: RequestInit) {
  return uploadImage(`${BASE_URL}/person/${personId}/photo`, photo, {
    method: 'PUT',
    headers: {
      ...init?.headers,
      'Content-type': 'image/jpeg'
    }
  });
}

export const getCountryNFPs = (isoAlpha3Code: string, init?: RequestInit): Promise<ICountryNFP[] | []> => {
  return fetchJson(`${BASE_URL}/country/${isoAlpha3Code}/nfp`, init);
};

export const getNFPProfile = (id: string, init?: RequestInit): Promise<INfpProfile> => {
  return fetchJson(`${BASE_URL}/person/${id}/nfp`, init);
};

export const updateNFPBackground = (
  { id, text }: { id: string, text: string; },
  init?: RequestInit
): Promise<ICountryNFP> => {
  return fetchJson(`${BASE_URL}/person/${id}/nfp`, {
    method: 'PUT',
    body: JSON.stringify({ background: text }),
    headers: {
      ...(init?.headers ?? {}),
      'Content-Type': 'application/json'
    },
    ...init
  });
};

export interface ICreateNewNfpCountryAssignment {
  personCode: string;
  primary: boolean;
  startDate: Date | string;
  endDate?: Date | string | null;
  fileGuid: string;
  countryCode: string;
}

export const createNewNfpCountryAssignment = (
  { personCode, primary, startDate, endDate, fileGuid, countryCode }: ICreateNewNfpCountryAssignment,
  init?: RequestInit
): Promise<ICountryNFP> => {
  return fetchJson(`${BASE_URL}/country/${countryCode}/nfp`, {
    method: 'POST',
    body: JSON.stringify({
      personCode,
      primary,
      startDate,
      endDate,
      fileGuid
    }),
    headers: {
      ...(init?.headers ?? {})
    },
    ...init
  });
};

export const editNfpCountryAssignment = (
  { startDate, fileGuid, id }: { startDate: Date | string, fileGuid: string, id: number; },
  init?: RequestInit
) => {
  return fetchJson<ICountryNFP>(`${BASE_URL}/nfp/${id}`, {
    method: 'PUT',
    body: JSON.stringify({
      startDate,
      fileGuid
    }),
    headers: {
      ...(init?.headers ?? {})
    },
    ...init
  });
};

export const editEndDateNfpCountryAssignment = (
  { endDate, id }: { endDate: Date, id: number; },
  init?: RequestInit
) => {
  return fetchJson<ICountryNFP>(`${BASE_URL}/nfp/${id}`, {
    method: 'PATCH',
    body: JSON.stringify({
      endDate
    }),
    headers: {
      ...(init?.headers ?? {})
    },
    ...init
  });
};

export const deleteNfp = ({ id }: { id: number; }, init?: RequestInit) => {
  return fetchJson(`${BASE_URL}/nfp/${id}`, {
    method: 'DELETE',
    headers: {
      ...(init?.headers ?? {})
    },
    ...init
  });
};

export const getCountriesTeams = (init?: RequestInit) => {
  return fetchJson<ICountryTeams[] | []>(`${BASE_URL}/country`, init);
};

export const uploadPersonNfpProofDocument = (params: {
  nfpId: string | number,
  file: File;
}, init?: RequestInit) => {
  return uploadSingleFile<{ guid: string; }>(`${BASE_URL}/nfp/${params.nfpId}/files`, params.file, {
    ...init,
    method: 'POST',
    headers: init?.headers
  });
};

export function sendFeedback(params: {
  text: string;
}, init?: RequestInit) {
  return fetchJson<IExpertAssignment>(`${BASE_URL}/feedback`, {
    method: 'POST',
    headers: init?.headers,
    body: JSON.stringify({
      text: params.text
    })
  });
}

export function getFeedbackList(params: {
  page: number,
  limit: number,
  sort?: 'asc' | 'desc' | null,
  order: string;
}, init?: RequestInit) {
  return fetchJson<{
    totalPages: number,
    items: FeedbackItem[];
  }>(`${BASE_URL}/feedback${CreateURLSearchParams({ ...params, sort: params.sort as string })}`, {
    method: 'GET',
    headers: init?.headers
  });
}

export function downloadPersonContactReport(params?: Record<string, string>,
  init?: RequestInit): Promise<IFileContent> {
  return fetchFile(`${BASE_URL}/report/contacts${new URLSearchParams(params).toString()}`, {
    ...init,
    method: 'GET',
  });
}

export interface IProfileUpdateDetails {
  createdDate?: string;
  createdBy?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  validation: IValidationData;
}