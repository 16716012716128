import React from 'react';

import { useAuthContext } from 'contexts/AuthContext';

const SignIn = React.lazy(() => import('pages/SignIn'));
const PublicRoutes = React.lazy(() => import('./PublicRoutes'));
const ProtectedRoutes = React.lazy(() => import('./ProtectedRoutes'));

const AppRouting: React.FC = () => {
  const { isInitialized, isPublicRoute } = useAuthContext();

  if (isPublicRoute) {
    return <PublicRoutes />;
  }

  if (!isInitialized) {
    return <SignIn />;
  }

  return <ProtectedRoutes />;
};

export default React.memo(AppRouting);
