import * as React from 'react';
import cn from 'classnames';

import { DividerOwnProps, Divider as MuiDivider } from '@mui/material';

import styles from './Divider.module.scss';

type Props = {
  noMargin?: boolean;
};

type DividerProps = Props & DividerOwnProps;

const VerticalDivider: React.FC<DividerProps> = () => (
  <MuiDivider
    data-test-id='verticalDivider'
    className={styles.divider}
    orientation='vertical'
  />
);

const HorizontalDivider: React.FC<DividerProps> = (props) => (
  <hr
    data-test-id='horizontalDivider'
    className={cn(styles.divider, { [styles.noMargin]: props.noMargin })}
  />
);

export const Divider = {
  Horizontal: HorizontalDivider,
  Vertical: VerticalDivider,
};
