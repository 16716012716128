import React from 'react';
import { Modal as MUIModal, Typography, Backdrop, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import styles from './Modal.module.scss';

type Props = {
  title: React.ReactNode;
  isOpen: boolean;
  onClose(): void;
  testId: string;
  customWidth?: string; /** example: '600px' */
}

const DEFAULT_WIDTH = '550px';

const Modal: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const width = props.customWidth ?? DEFAULT_WIDTH;
  return (
    <MUIModal
      className={styles.modal}
      open={props.isOpen}
      onClose={props.onClose}
      slots={{ backdrop: Backdrop }}
      data-test-id={props.testId}
    >
      <Box className={styles.container} sx={{ width }}>
        <div className={styles.body}>
          <header className={styles.header}>
            <Typography className={styles.title}>{props.title}</Typography>
            <IconButton onClick={props.onClose}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </header>
          <main className={styles.content}>
            {props.children}
          </main>
        </div>
      </Box>
    </MUIModal>
  );
};

export default React.memo(Modal);
