import * as React from 'react';

import cn from 'classnames';

import { Typography, CircularProgress } from '@mui/material';

import styles from './Loader.module.scss';

type Props = {
  text?: string;
  className?: string;
  large?: boolean;
  center?: boolean;
};

const LARGE_SIZE = 40;
const SMALL_SIZE = 20;

const Loader: React.FC<Props> = (props) => (
  <div className={cn(props.className, {[styles.center]: props.center })} data-test-id='uiLoader'>
    <CircularProgress size={props.large ? LARGE_SIZE : SMALL_SIZE} color='secondary'/>
    <Typography variant='body2' component='div'>
      {props.text}
    </Typography>
  </div>
);

export default React.memo(Loader);
