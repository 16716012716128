import React from 'react';
import {createTheme} from "@mui/material";
import palette from "./palette";
import shape from "./shape";
import typography from "./typography";
import { Link } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import type {} from '@mui/x-data-grid/themeAugmentation';
import typographyPrint from './typography-print';

const whoTheme = createTheme({
  palette: palette,
  typography: typography,
  shape: shape,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          boxSizing: "border-box",
        }
      }
    },
    MuiLink: {
      defaultProps: {
        component: ({ href, ...restProps }) => <Link to={href} {...restProps} />
      } as LinkProps<React.ElementType>,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: ({ href, ...restProps }) => <Link to={href} {...restProps} />,
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: "16px 0",
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: palette.grey[600]
          },
        },
        contained: {
          backgroundColor: palette.secondary.main
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFF',
          color: palette.primary.main,
        },
        paperAnchorRight: {
          backgroundColor: '#FFF',
          color: palette.grey[900]
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: palette.background.default
        },
      }
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: palette.grey[200],
            '&:hover': {
              backgroundColor: palette.grey[200],
            },
          },

        },
        head:
        {
          backgroundColor: palette.grey[200],
        }
      }
    },

    MuiTableCell: {
      styleOverrides: {


        head: ({ ownerState, theme }) => ({
          fontWeight: 700,
          color: palette.text.secondary,
          '&:first-of-type': {
            borderTopLeftRadius: shape.borderRadius,
            borderBottomLeftRadius: shape.borderRadius,

          },
          '&:last-of-type': {
            borderTopRightRadius: shape.borderRadius,
            borderBottomRightRadius: shape.borderRadius,

          }}
        ),
      }
    },

    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.MuiStepIcon-root": {
            color: palette.grey[400]
          },
          "&.Mui-active": {
            color: palette.primary.main
          },
          "&.Mui-completed": {
            color: palette.green.dark
          },
        },

      },
    },

    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          fontSize: "0.7rem"
        },
        colorWarning: {
          backgroundColor: '#F4A81D',
        },
        colorPrimary: {
          backgroundColor: '#0B9ADE',
        }
      }
    },

    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: palette.grey[200],
        }
      }
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
            backgroundColor: palette.grey[100],
          },

          '& .MuiFilledInput-input': {
            backgroundColor: palette.secondary.light,
          }

        }
      }
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: palette.grey[400],
          },
        }
      }
    },

    MuiFormControlLabel : {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            WebkitTextFillColor: "#000000",
          },
        }
      }
    }

    
  }
});

const printTheme = createTheme({
  ...whoTheme, 
  typography: typographyPrint,
},
);

export  { whoTheme, printTheme};
