import * as msal from '@azure/msal-browser';
import React from 'react';
import { PUBLIC_ROUTE_PATH } from './authConfig';
import { AuthContextProvider } from 'contexts/AuthContext';
import { AuthUser } from 'models/auth';
import { fetchMsalToken, msalInstance } from './msalInstance';
import { getAuthData } from 'api/auth-api';
import { useLocation } from 'react-router-dom';
import { AppModalsContext } from '../../contexts/AppModalsContext';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useUserStore } from 'stores/user';
import { LogoutModal } from './LogoutModal';
import { Loader } from 'components/ui/Loader';

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<msal.AccountInfo | null>();
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | undefined>(undefined);
  const [isInitialized, setIsInitialized] = React.useState<boolean | undefined>(undefined);
  const [userContext, setUserContext] = React.useState<AuthUser>();
  const [isShowLogoutModal, setShowLogoutModal] = React.useState(false);

  const modalsContext = React.useContext(AppModalsContext);
  const location = useLocation();
  const currentPath = location.pathname;
  const { setUser : setUserState, resetUser } = useUserStore();

  React.useEffect(() => {
    if (!isAuthenticated) return;
    const getUserContext = async () => {
      try {
        const token = await fetchMsalToken();
        const bearerToken = `Bearer ${token}`;
        const userContextData = await getAuthData({
          headers: { 'Authorization': bearerToken }
        });
        setUserContext(userContextData);
        setIsAuthenticated(true);
        setIsInitialized(true);
        setUserState(userContextData);
      } catch (error: any) {
        setUser(null);
        setIsAuthenticated(false);
        setIsInitialized(false);
        if (error?.statusCode === 403) {
          setShowLogoutModal(true);
          return;
        }
        // not display modal for interaction error
        if (!(error instanceof InteractionRequiredAuthError)) {
          modalsContext.setApiErrorModal(error);
        }
      }
    };

    if (currentPath.split('/')[1] !== PUBLIC_ROUTE_PATH) {
      getUserContext();
    }

  }, [isAuthenticated, user]);

  React.useEffect(() => {
    const initialize = async () => {
      try {
        await msalInstance.handleRedirectPromise();

        const accounts = msalInstance.getAllAccounts();

        if (accounts.length > 0) {
          msalInstance.setActiveAccount(accounts[0]);
          setUser(accounts[0]);
          setIsAuthenticated(true);
        } else {
          setUser(null);
          setIsAuthenticated(false);
          setIsInitialized(false);
        }
      } catch (error) {
        console.log('Error during login: initialize');
        setUser(null);
        setIsAuthenticated(false);
        setIsInitialized(false);
      }
    };

    if (currentPath.split('/')[1] !== PUBLIC_ROUTE_PATH) {
      initialize();
    } else {
      setUser(null);
      setIsAuthenticated(false);
      setIsInitialized(false);
    }
  }, []);

  const handleLogout = (): void => {
    setShowLogoutModal(false);
    logout();
  };

  const login = () => {
    msalInstance.loginRedirect();
  };

  const logout = () => {
    msalInstance.logoutRedirect().then(resetUser);
  };

  const value = {
    isAuthenticated,
    isInitialized,
    user,
    login,
    logout,
    getToken: fetchMsalToken,
    contextUser: userContext,
    isPublicRoute: currentPath.split('/')[1] === PUBLIC_ROUTE_PATH
  };

  if (isInitialized === undefined) {
    return <Loader large center />;
  }

  return (
    <>
      {isShowLogoutModal && <LogoutModal onLogout={handleLogout}/>}
      <AuthContextProvider value={value}>{children}</AuthContextProvider>
    </>
  );
}

