import React from 'react';
import cn from 'classnames';

import { Grid } from '@mui/material';

import { GridProps } from '../types';

import styles from './Col.module.scss';

type Props = {
  mobile?: number;
  tablet?: number;
  laptop?: number;
  desktop?: number;
  desktop2k?: number;
  testId?: string;
  noBottomMargin?: boolean;
  className?: string;
}

type ColProps = Props & GridProps;

const Col: React.FC<React.PropsWithChildren<ColProps>> = (props) => {
  const {
    testId = 'gridCol',
    className,
    noBottomMargin,
    mobile,
    tablet,
    laptop,
    desktop,
    desktop2k,
    ...colProps
  } = props;

  const classes = cn([className, styles.col, { [styles.noMargin]: noBottomMargin }]);

  const mediaProps = {
    xs: mobile,
    sm: tablet,
    md: laptop,
    lg: desktop,
    xl: desktop2k,
  };

  return (
    <Grid
      item
      data-test-id={testId}
      className={classes}
      {...mediaProps}
      {...colProps}
    >
      {props.children}
    </Grid>
  );
};

export default React.memo(Col);