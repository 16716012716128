import React from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { Button as MUIButton } from '@mui/material';

import { LoadingPlaceholder } from 'components/CommonComponents/LoadingPlaceholder';
import { ButtonTheme, ButtonSize } from './types';

import styles from './Button.module.scss';

type Props = {
  testId: string;
  onClick?(): void;
  className?: string
  disabled?: boolean;
  fullWidth?: boolean;
  asText?: boolean;
  isGhost?: boolean;
  submit?: boolean;
  isLoading?: boolean;
  to?: string;
  color?: ButtonTheme;
  size?: ButtonSize;
}

const Button: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const size = props.size ?? ButtonSize.Large;
  const navigate = useNavigate();
  const btnClasses = cn([styles.button, props.className, styles[size]], {
    [styles.ghost]: props.isGhost,
  });

  const variant = React.useMemo(() => {
    if (props.isGhost) {
      return 'outlined';
    }
    return props.asText ? 'text' : 'contained';
  }, []);

  const handleClick = (): void => {
    if (props.to) {
      navigate(props.to);
    }
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <MUIButton
      onClick={handleClick}
      data-test-id={props.testId}
      className={btnClasses}
      disabled={props.disabled || props.isLoading}
      fullWidth={props.fullWidth}
      type={props.submit ? 'submit' : 'button'}
      variant={variant}
      color={props.color}
    >
      {props.isLoading ? <LoadingPlaceholder text=' ' size={20} /> : props.children}
    </MUIButton>
  );
};

export default React.memo(Button);