import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';

import { queryClient } from 'config/queryClient';
import { ErrorBoundary } from 'components/CommonComponents/ErrorBoundary';
import { Loader } from 'components/ui/Loader';
import AuthProvider from 'components/Auth/AzureAuthProvider';
import { AppModalsContextProvider } from 'contexts/providers/AppModalsContextProvider';

import AppRouting from './components/AppRouting';
import AppTheme from './components/AppTheme';
import CoreDataLoader from './components/CoreDataLoader';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AppModalsContextProvider>
          <AppTheme>
            <ErrorBoundary>
              <AuthProvider>
                <CoreDataLoader>
                  <React.Suspense fallback={<Loader large center />}>
                    <AppRouting />
                  </React.Suspense>
                </CoreDataLoader>
              </AuthProvider>
            </ErrorBoundary>
          </AppTheme>
        </AppModalsContextProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;